<template>
  <BaseDialog size-class="!max-w-sm" :model-value="show" @update:model-value="$emit('close')">
    <template #default>
      <div class="flex flex-col items-center text-base font-medium leading-6">
        <ChatImage size="xxl" :item="user" :show-online="true" />
        <div class="pt-5">
          <BaseUser
            :stream-companies="streamCompanies"
            :title="userName"
            :user="user"
            :user-profile-opened="true"
            :hide-cursor="true"
          >
          </BaseUser>
        </div>
      </div>
      <div class="p-4 text-center text-xs font-medium">
        <p v-if="user.about" class="user_about text-gray-100">{{ user.about }}</p>
      </div>
      <div
        v-if="client.user.id !== user.id && user.id !== 'bot_user' && !user.deactivated_at"
        class="flex flex-row items-center justify-center gap-10 pt-4"
      >
        <BaseButton class="px-[4.5rem]" @press="$emitter.$emit('send-dm', user)">Message</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import BaseUser from './ChatBaseUser.vue';
import ChatImage from './ChatImage.vue';
import { useMapGetter } from '@/store/map-state';
import { useStore } from 'vuex';
import useEmitter from '@/composeables/emitter';

const $store = useStore();
const $emitter = useEmitter();
const { client } = useMapGetter();
const $emits = defineEmits(['close']);

const props = defineProps({
  user: Object
});

const streamCompanies = ref(null);
onMounted(() => {
  streamCompanies.value = $store.getters.streamCompanies;
});
const show = ref(true);
const userName = computed(() => {
  return props.user.name;
});
</script>
